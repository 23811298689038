<template>
    <div ref="rate" class="scroll-div" style="padding-top: 0;">
        <template v-if="message === 405">
            <div class="corp-sale-body" style="padding: 20px;">
                <ts-error></ts-error>
            </div>
        </template>
        <!--<ts-table ref="table" class="flex-item" :cols="cols" :data="tableList" :loading="loading"-->
        <!--hide-detail hide-page :height="null"></ts-table>-->
        <ts-table ref="table" :cols="cols" :data="list" :page-param="pageParam" :loading="loading" @handleSort="sort"
            :error="pageParam.error" :error-code="error" :name="nameKey"
            @handleRowDbClickChange="handleRowDbClickChange" hide-detail :height="null"
            @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
            :style="{ height: realHeight + 'px' }" full zero-to-empty>
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter"></ts-search-left>
            </template>
            <template #left>
                <ts-table-operate :cols="cols" :table-name="tableName" show-export @leadingOut="exportInfo()"
                    @handleFilter="handleFilter" style="margin: 0 20px;align-items: center;"
                    @searchShowLeft="searchShowLeft">
                    <div>筛选：
                        <ts-year-select v-model="year" @change="init"></ts-year-select>
                    </div>
                </ts-table-operate>
            </template>
        </ts-table>

        <template v-if="!pageParam.error">
            <div class="total-title__line"></div>
            <div class="total-title">药品剂型销售占比图</div>
            <div :id="nameKey" class="canvas small" v-loading="loading"></div>
        </template>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
export default {
    mixins: [myMixin],
    props: ['comName', 'baseQuery', 'visitId'],
    data() {
        return {
            nameKey: 'newDrugRate',
            cols: this.$help.generalCols([
                this.$variable.indexColumn.drug({ listQueryView: "否" }),
                this.$variable.indexColumn.corp(),
                {
                    fieldName: 'unifySpec',
                    name: '规格',
                    listQueryView: "是",
                    width: 150,
                    listClass: 'tc',
                    listQueryModel: 1
                },
                {
                    fieldName: 'unifyDose',
                    name: '剂型',
                    listQueryView: "是",
                    width: 90,
                    listClass: 'tc'
                },
                {
                    fieldName: 'amount',
                    name: '销售金额',
                    listQueryView: "是",
                    width: 90,
                    listClass: 'tc',
                    listOrderView: '是'
                },
                {
                    fieldName: 'ratio',
                    name: '销售额占比%',
                    width: 100,
                    listClass: 'tc'
                },
            ]),
            tableName: 'sale_year',
            pageApi: 'drug/saleYear/sumRatioGroupByComNameAndCompNameAndDoseAndSpecByComNameAndYear',
            sourceList: [],
            message: '',
            year: this.$variable.lastYear.key,
            realHeight: 100,
        }
    },
    mounted() {
        this.realHeight = this.$refs.rate.clientHeight - 50
    },
    methods: {
        paramFunc(query) {
            // query.sort = {amount: "desc"}
            query.model = this.$variable.yearMap[this.year]
            return query
        },
        listComputed(list, res) {
            list.map(item => {
                item.ratio = this.$help.parseFloat100(item.ratio)
                return item
            })
            if (res.currentPage === 1) {
                this.$nextTick(() => {
                    this.packageData(this.$help.deepClone(list))
                })
            }
            return list
        },
        packageData(list) {
            let total = 0
            if (list.length > 10) {
                list = list.splice(0, 10)
            }

            list.map(item => {
                item.name = `${item.compName} ${item.unifySpec}`
                item.value = item.ratio
                total += item.value
                return item
            })

            if (total < 100 && list.length === 10) {
                list.push({
                    name: '其他',
                    value: 100 - total
                })
            }
            this.$help.renderPie(this.nameKey, list)
        },
        exportInfo(txt = '确定导出吗', query = {}) {
            this.$store.commit('confirm', {
                content: txt,
                callback: (next, back) => {
                    let search = this.getParam()
                    search = this.$help.joinObj(search,{ model: { yearKey: this.year, force: query.force }, params: this.baseQuery, })
                    console.log('参数1111',search)
                    this.$api.post('drug/saleYear/exportSumRatioGroupByComNameAndCompNameAndDoseAndSpecByComNameAndYear',
                    search
                    )
                        .then(res => {
                            if (!(res.success || res.code === 'N0001')) {
                                next()
                                this.$store.commit('error', res.message)
                            } else if (res.code === 'N0001') {
                                next()
                                this.exportInfo(res.message, { force: true })
                            } else {
                                this.$help.getExportStatus(res.data.id, data => {
                                    if (data === true) {
                                        next()
                                        this.$store.commit('success', '导出成功')
                                    } else {
                                        back()
                                        this.$store.commit('error', data)
                                    }
                                })
                            }
                        })
                        .catch(back)
                },
            })
        },
    }
}
</script>